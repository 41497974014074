import React from 'react';
import { Global, css } from '@emotion/react';
import { Helmet } from 'react-helmet';
import useSiteMetaData from '../hooks/useSiteMetaData';
import Header from './Header';
import { MainContainer } from '../style';

const Layout = function ({ children }) {
  const { title, description, author } = useSiteMetaData();

  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
          }

          body,
          div#___gatsby,
          div#gatsby-focus-wrapper {
            width: 100%;
          }

          body {
            overflow-x: hidden;
            font-family: 'Roboto', sans-serif;
          }

          div#___gatsby {
            width: 100%;
          }

          a {
            text-decoration: none;
            color: inherit;

            &:hover {
              transition: all 400ms ease;
            }
          }

          li {
            list-style: none;
            oveflow-x: hidden;
          }
        `}
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta description={`${description}`} />
        <meta author={`${author}`} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Playfair+Display&family=Source+Sans+Pro&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <Header />
      <MainContainer>{children}</MainContainer>
    </>
  );
};

export default Layout;
