import styled from '@emotion/styled';
import { mq } from '../utils';

export const ShowcaseSection = styled.section`
  position: relative;
  width: 100%;
  z-index: 0;

  ${mq('iphoneSE', 'min')} {
    height: 100%;
    min-height: calc(926px - 137px);
  }

  ${mq('desktopS', 'min')} {
    height: 100%;
    min-height: calc(100vh - 80px);
  }
`;

export const ShowcaseWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const BodyWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ShowcaseBody = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.textColorInv};

  ${mq('iphoneSE', 'min')} {
    padding: 1rem;
  }
`;

export const Title = styled.h1`
  font-family: ${(props) => props.theme.headingFont};
  font-weight: normal;
  text-align: center;

  ${mq('iphoneSE', 'min')} {
    padding-top: 4rem;
    font-size: 1.5rem;
  }

  ${mq('desktopS', 'min')} {
    padding-top: 20rem;
    font-size: 4.5rem;
  }
`;

export const Description = styled.p`
  text-align: center;

  ${mq('iphoneSE', 'min')} {
    font-size: ${(props) => props.theme.helperText};
    padding: 1.2rem 2rem;
    margin-top: 1rem;
  }

  ${mq('desktopS', 'min')} {
    padding-top: 1.5rem;
    font-size: ${(props) => props.theme.paragraph};
  }
`;

export const LinkWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  z-index: 0;

  & > a {
    display: inline-block;
    position: relative;
    padding: 0.8rem 2rem;
    border-radius: 30px;
    border: solid #ffffff 1px;
    z-index: 0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-origin: center;
      transform: scale(0);
      background-color: #ffffff;
      pointer-events: none;
      z-index: -1;
    }

    &:hover {
      color: ${(props) => props.theme.textColor};
    }

    &:hover::after {
      transform: scale(100%);
      border-radius: 30px;
      transition: all 400ms ease;
    }
  }
`;

export const DarkBG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.4);
  z-index: 0;

  ${mq('iphoneSE', 'min')} {
    height: 100vh;
    min-height: 926px;
  }
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  overflow-y: hidden;
  ${mq('iphoneSE', 'min')} {
    height: 100vh;
    min-height: 926px;
  }
`;

export const ImgContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;
