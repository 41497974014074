import { MOBILE, DESKTOP } from './types';

const appReducer = function (state, action) {
  switch (action.type) {
    case DESKTOP:
      return {
        ...state,
        device: 'desktop',
        aside: false,
      };

    case MOBILE:
      return {
        ...state,
        device: 'mobile',
      };

    default:
      return {
        ...state,
      };
  }
};

export default appReducer;
