export const logoFont = "'Comfortaa', sans-serif";
export const headingFont = "'Playfair Display', sans-serif";
export const paragraphFont = "'Source Sans Pro', sans-serif";
export const header1 = '3rem';
export const header2 = '2rem';
export const header3 = '1.4rem';
export const header4 = '1.2rem';
export const header5 = '1.1rem';
export const paragraph = '1rem';
export const helperText = '0.8rem';
export const copyRightText = '0.7rem';
