import styled from '@emotion/styled';
import { mq } from '../utils';

export const Header = styled.header`
  position: sticky;
  width: 100%;
  color: ${(props) => props.theme.textColorInv};
  z-index: 20;
  top: 0;
  transition: all 400ms ease;

  ${mq('iphoneSE', 'min')} {
    height: auto;
  }

  ${mq('desktopS', 'min')} {
    height: 80px;
  }

  &.scroll-up {
    transform: translateY(80px);
    background-color: ${(props) => props.theme.bgColorWht};
    color: ${(props) => props.theme.textColor};
  }

  &.scroll-up-exit {
    transform: translateY(0);
  }

  &.scroll-down {
    ${mq('iphoneSE', 'min')} {
      top: -137px;
    }

    ${mq('desktopS', 'min')} {
      top: -80px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  ${mq('tiny', 'min')} {
    flex-direction: column;
    justify-content: center;
  }

  ${mq('desktopS', 'min')} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LogoHeading = styled.h1`
  font-family: ${(props) => props.theme.logo};
  font-size: ${(props) => props.theme.header2};

  ${mq('tiny', 'min')} {
    padding: 1rem;
    width: 100%;
    text-align: center;
  }

  ${mq('desktopS', 'min')} {
    width: 50%;
    padding: 0;
    text-align: left;
  }
`;

export const MainNav = styled.nav`
  ${mq('tiny', 'min')} {
    width: 100%;
  }

  ${mq('desktopS', 'min')} {
    width: 50%;
    height: 100%;
  }
`;

export const NavList = styled.ul`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  font-family: ${(props) => props.theme.headingFont};

  ${mq('tiny', 'min')} {
    justify-content: center;
    padding: 1rem;
    font-size: ${(props) => props.theme.paragraph};
  }

  ${mq('desktopS', 'min')} {
    justify-content: flex-end;
    padding: 0;
    font-size: ${(props) => props.theme.paragraph};
  }
`;

export const ListItem = styled.li`
  ${mq('iphoneSE', 'min')} {
    padding: 0.5rem;
  }

  ${mq('desktopS', 'min')} {
    padding: 0 1rem;
  }
`;
