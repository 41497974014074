import styled from '@emotion/styled';
import { mq } from '../utils';

export const ThemeColorDefaults = styled.div`
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.textColor};
`;

export const MaxContainer = styled.div`
  ${mq('tiny', 'min')} {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  ${mq('desktopS', 'min')} {
    max-width: 1280px;
    margin: 0 auto;
  }
`;

export const MainContainer = styled.main`
  width: 100%;
  height: auto;
`;

export const Section = styled.section`
  position: relative;
  width: 100%;
  z-index: 1;

  ${mq('iphoneSE', 'min')} {
    height: 100vh;
    min-height: 926px;
  }
`;

export const SectionWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const AbsoluteWrapper1 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

// Single Column Flex Container
export const FlxContainer1 = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const ContainImageBG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`;
