import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { theme } from './src/utils';
import Layout from './src/components/Layout';
import AppProvider from './src/context/AppProvider';

export const wrapPageElement = function ({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = function ({ element }) {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>{element}</AppProvider>
    </ThemeProvider>
  );
};
