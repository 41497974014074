import { Link } from 'gatsby';
import React from 'react';
import {
  Header,
  HeaderWrapper,
  ListItem,
  LogoHeading,
  MainNav,
  MaxContainer,
  NavList,
} from '../style';

const HeaderComponent = function () {
  return (
    <Header>
      <MaxContainer>
        <HeaderWrapper id="header-wrapper">
          <LogoHeading>edification</LogoHeading>
          <MainNav>
            <NavList>
              <ListItem>
                <Link to="#home">Home</Link>
              </ListItem>
              <ListItem>
                <Link to="#about">About</Link>
              </ListItem>
              <ListItem>
                <Link to="#mission">Mission</Link>
              </ListItem>
            </NavList>
          </MainNav>
        </HeaderWrapper>
      </MaxContainer>
    </Header>
  );
};

export default HeaderComponent;
