import React, { useReducer } from 'react';

import AppContext from './AppContext';
import appReducer from './appReducer';

const AppProvider = function ({ children }) {
  const [state, dispatch] = useReducer(appReducer, {
    device: null,
  });

  return (
    <AppContext.Provider
      value={{
        device: state.device,
        dispatch: dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
