import {
  bgColorWht,
  bgColorBlue,
  bgColorPink,
  textColor,
  textColorInv,
} from './colors';
import {
  logoFont,
  headingFont,
  paragraphFont,
  header1,
  header2,
  header3,
  header4,
  header5,
  paragraph,
  helperText,
  copyRightText,
} from './typography';

export const theme = {
  textColor,
  textColorInv,
  bgColorWht,
  bgColorPink,
  bgColorBlue,
  logo: logoFont,
  headingFont: headingFont,
  paragraphFont: paragraphFont,
  header1,
  header2,
  header3,
  header4,
  header5,
  paragraph: paragraph,
  helperText,
  copyRightText,
};
