import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetaData = function () {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `);

    const { title, description, author } = site;

    return {
        title,
        description,
        author,
    };
};

export default useSiteMetaData;
